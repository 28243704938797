// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-inside-checkbox {
  opacity: 1;
  white-space: pre-wrap;
  word-wrap: anywhere;
  align-self: end;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-checkbox-input/curafida-checkbox-input.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;AACJ","sourcesContent":[".label-inside-checkbox {\n    opacity: 1;\n    white-space: pre-wrap;\n    word-wrap: anywhere;\n    align-self: end\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
