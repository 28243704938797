import { Genders } from './gender';

export class CreateUserDto {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    birthdate: string;
    gender: string = Genders.NONE;
    password: string;
    isEmployed: boolean; // type prüfen -> null oder undefined heißt keine Angabe
    job: string;
    company: string;
    pseudonym?: string;
    usePseudonym: boolean;

    constructor(username?: string) {
        this.username = username;
    }
}
