import { AccountType } from './account-type';
import { Genders } from './gender';
import { UserCustomPropertyDto } from './user-custom-property';

export class LoginUserWithoutPasswordDto {
    accountType: AccountType;
    username: string;
    title: string;
    firstname: string;
    lastname: string;
    birthdate: string;
    gender: string = Genders.NONE;
    email: string;
    isEmployed: boolean; // type prüfen -> null oder undefined heißt keine Angabe
    job: string;
    company: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    country?: string;
    phone: string;
    mobilePhone: string;
    pseudonym?: string;
    usePseudonym: boolean;
    customPropertyDtos?: UserCustomPropertyDto[];
}
