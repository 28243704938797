import { AccountType } from './account-type';
import { Genders } from './gender';
import { UserCustomPropertyDto } from './user-custom-property';

export class PlainUserDto {
    accountType: AccountType = AccountType.PLAIN_USER;
    title?: string;
    username: string;
    firstname?: string;
    lastname?: string;
    birthdate?: string;
    gender?: string = Genders.NONE;
    pvsId?: string;
    streetAddress?: string;
    postalCode?: string;
    city?: string;
    pseudonym?: string;
    usePseudonym?: boolean;
    email?: string;
    country?: string;
    phone?: string;
    mobilePhone?: string;
    isEmployed?: boolean;
    job?: string;
    company?: string;

    customPropertyDtos?: UserCustomPropertyDto[];
}
