import { User } from './user';
import { UserCustomPropertyDto } from './user-custom-property';

export class UpdateUserDto {
    title?: string;
    firstname?: string;
    lastname?: string;
    disabled?: boolean;
    birthdate?: string;
    gender?: string;
    pvsId?: string;
    streetAddress?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone: string;
    mobilePhone: string;
    isEmployed: boolean; // type prüfen -> null oder undefined heißt keine Angabe
    job: string;
    company: string;
    pseudonym?: string;
    usePseudonym: boolean;
    email?: string;

    customPropertyDtos?: UserCustomPropertyDto[];
}

export class UpdateUserDtoBuilder {
    private updateUserDto = new UpdateUserDto();

    setUserJob(job: string): UpdateUserDtoBuilder {
        this.updateUserDto.job = job;
        return this;
    }

    copyFromUser(user: User): UpdateUserDtoBuilder {
        this.updateUserDto.title = user.title;
        this.updateUserDto.firstname = user.firstname;
        this.updateUserDto.lastname = user.lastname;
        this.updateUserDto.disabled = user.disabled;
        this.updateUserDto.birthdate = user.birthdate;
        this.updateUserDto.gender = user.gender;
        this.updateUserDto.pvsId = user.pvsId;
        this.updateUserDto.streetAddress = user.streetAddress;
        this.updateUserDto.postalCode = user.postalCode;
        this.updateUserDto.city = user.city;
        this.updateUserDto.country = user.country;
        this.updateUserDto.phone = user.phone;
        this.updateUserDto.mobilePhone = user.mobilePhone;
        this.updateUserDto.isEmployed = user.isEmployed; // type prüfen -> null oder undefined heißt keine Angabe
        this.updateUserDto.job = user.job;
        this.updateUserDto.company = user.company;
        this.updateUserDto.pseudonym = user.pseudonym;
        this.updateUserDto.usePseudonym = user.usePseudonym;
        // this.updateUserDto.email = user.email;
        return this;
    }

    build(): UpdateUserDto {
        return this.updateUserDto;
    }
}
