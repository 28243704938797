import { UserRoles } from './user-roles';
import { CreateUserDto } from './create-user-dto';
import { AccountType } from './account-type';
import { UserTag } from '../../../user/entities/user-tag/user-tag.entity';
import { UserCustomProperty } from './user-custom-property';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';

export class User extends CreateUserDto {
    id: number;
    accountType: AccountType;
    roles: UserRoles[];
    role: UserRoles;
    title: string;
    disabled: boolean;
    emailVerified: boolean;
    created_at: string;
    updated_at: string;
    lastActivityDate?: string;
    kcId?: string;
    pvsId?: string;
    postalCode?: string;
    city?: string;
    streetAddress?: string;
    country?: string;
    phone: string;
    mobilePhone: string;
    debuggingEnabled?: boolean; // This Flag is used to enable the logViewer feature (Specially for mobile devices) for this user
    groups?: string[];
    tags: UserTag[];
    customProperties?: UserCustomProperty[];
    patientNumber: number;

    /**
     * Randomized controlled trial group assignment.
     */
    randomizationGroup?: 'A' | 'B';

    // Frontend State
    fullName: string;
    actions?: ActionMenuItem[];

    constructor(args?: { username?: string; roles?: UserRoles[] }) {
        super();
        this.username = args?.username;
        this.roles = args?.roles;
    }
}
