import { ExerciseDto } from './exercise-dto';
import { FeedBack } from '../feedback';
import { ExerciseSession, ExerciseSessionOfUser, ExerciseType } from '../exerciseSession';
import { Content } from '../content';
import { ExerciseGoal, PhysicalExerciseGoalDto } from '../exerciseGoal/exerciseGoalDto';
import { Tag } from '../tag/tag.entity';
import { SafeUrl } from '@angular/platform-browser';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';

export class Exercise extends ExerciseDto {
    id: number;
    created_at: string;
    updated_at: string;
    therapyExercise_id?: number;
    therapyTemplateExercise_id?: number;
    contents?: Content[];
    feedbacks: FeedBack[];
    isOpen: boolean;
    selected = true;
    exerciseSessions?: ExerciseSession[];
    exerciseSessionsOfUser?: ExerciseSessionOfUser[];
    alternativeExercises?: Exercise[];
    exerciseGoals?: ExerciseGoal[];
    plannedExercises: number;
    exerciseGoal: PhysicalExerciseGoalDto;
    childExercises?: ChildExercise[];
    notifyOnStartTime?: boolean;
    notifyOnEndTime?: boolean;
    notifyOnDelayedTime?: boolean;
    exerciseParameters: string;
    patientCanOpenResult?: boolean;
}

export class CourseMetaInfo {
    preparationInfo: string;

    constructor(preparationInfo?: string) {
        this.preparationInfo = preparationInfo;
    }
}

export class ExerciseCatalog extends Exercise {
    url?: string | SafeUrl;
    mimeType?: string;
    tagsList?: string;
    tagsArray?: Tag[];
    openDisabled?: boolean;
    actions?: ActionMenuItem[];
    type?: string;
}

export class ChildExerciseResource extends Exercise {
    childId: number;
    parentId: number;
    childOrder: number;

    //frontend variable
    url: string | SafeUrl;
    mimeType: string;
    tagsList: string;
    tagsArray: Tag[];
    openDisabled: boolean;
    actions: ActionMenuItem[];
}

export class CourseCatalogExercise extends ExerciseCatalog {
    exerciseType = ExerciseType.COURSE;
    metaInfo: CourseMetaInfo;
}

export class ReorderChildExercisesDto {
    ids: number[];
}

export class ChildExercise {
    parentId: number;
    childId: number;
    childOrder: number;
    childExercise: Exercise;
    url: string | SafeUrl;
    mimeType: string;
    // frontend Variable
    id?: number;
    constructor(parentId?: number, childId?: number, childOrder?: number) {
        this.parentId = parentId;
        this.childId = childId;
        this.childOrder = childOrder;
    }
}
