import {
    ExerciseSessionCalendarEvent,
    ExerciseSessionState,
    ExerciseSessionType,
    ExerciseSessionUserState,
    ExerciseSubType,
    ExerciseToolSettings,
    ExerciseType,
    PresenceType,
} from '../../entities/exerciseSession';
import { ExerciseSessionAppointment } from '../../entities/appointement';
import { TaskTemplatePermissionDto } from '../../pages/task/task-template-detail/task-template.model';
import { HypermediaLinks } from '../../../hateoas/hateoas.model';
import { ActionButton } from '../../../table/entities/action-menu.item';
import { ExerciseSessionUserResult } from '../../entities/exerciseSession/exercise-session-user-result';
import { UserRoles } from '../../../auth/entities/user';
import { Content } from '../../entities/content';
import { Tag } from '../../entities/tag/tag.entity';
import { TaskTemplateResource } from '../../entities/exercise/task-template.resource';
import { IconAdapterElement } from '../../../table/components/table-adapter/icon-adapter.component';
import { ExerciseSessionStateChange } from '../../entities/exerciseSession/exercise-session-state-change';
import { XapiLesson } from '../../entities/xapi/xapi-finished-lessons.dto';
import { MeetingUserPreviewResource } from '../../../meeting/resources/meeting-user-preview.resource';
import { PublicUserInfo } from '../../../user/entities/public-user-info.resource';

/**
 * Task related actions to perform
 */
export enum TaskActionLinkName {
    /** Zuständige person startet Aufgabe. */
    RESPONSIBLE_ACTIVATE = 'responsibleActivate',

    /** Zuständige person bricht Aufgabe ab. */
    RESPONSIBLE_CANCEL = 'responsibleCancel',

    /** Zuständige person nimmt Aufgabe wieder auf. */
    RESPONSIBLE_RESUME = 'responsibleResume',

    /** Zuständige person schließt Aufgabe als erledigt ab. */
    RESPONSIBLE_FINISH = 'responsibleFinish',

    /** Planung der Aufgabe abscgließen. */
    CONFIRM_PLANNING = 'confirmPlanning',
}

export interface Person {
    username: string;
    firstname: string;
    lastname: string;
}

export interface TaskResponsibility {
    role: UserRoles;
    person?: Person;
}

export interface PreconditionsSummary {
    hasPreconditions: boolean;
    arePreconditionsCompleted: boolean;
}

export interface TaskPreviewResource {
    id: number;
    title: string;
    concernedPerson: Person;
    responsibility: TaskResponsibility;
    exerciseSessionState: ExerciseSessionState;
    exerciseSessionUserState: ExerciseSessionUserState;
    lessons?: XapiLesson[];
    learningProgress?: number;
    exerciseSubType: ExerciseSubType;
    appointment?: ExerciseSessionAppointment;
    preconditionsSummary: PreconditionsSummary;
    calendarEvent: ExerciseSessionCalendarEvent;
    icons: IconAdapterElement[];
    _links: HypermediaLinks;

    // Frontend state extension
    state?: string;
    dueDate?: string;
    stateChanges?: ExerciseSessionStateChange[];
    finished_at: any;
    patientName: string;
    responsibleName: string;
    responsibleFullName: string;
    participants?: any;
    actions: ActionButton[];
    iconColor?: string;
    dashedCardBorder?: boolean;

    calendarEventStartDate: string;
}

export class TaskResource implements AppointmentItem {
    id: number;
    title: string;
    description: string;
    concernedUsername: string;
    duration?: string;
    exerciseSessionType: ExerciseSessionType;
    exerciseSessionState: ExerciseSessionState;
    exerciseSessionUserState: ExerciseSessionUserState;
    responsibility: TaskResponsibility;
    exerciseType?: ExerciseType;
    exerciseToolSettings?: ExerciseToolSettings;
    exerciseSubType?: ExerciseSubType;
    metaInfo?: object;
    notifyOnStartTime?: boolean;
    notifyOnEndTime?: boolean;
    notifyOnDelayedTime?: boolean;
    isAutoFinished?: boolean; // Optional: only Questionnaires have this feature
    appointment?: ExerciseSessionAppointment;
    calendarEvent?: ExerciseSessionCalendarEvent;
    created_at?: string;
    updated_at?: string;
    latestStateChange?: Date;
    isPatientParticipant: boolean;
    permissions: TaskTemplatePermissionDto[];
    exerciseSessionUserResults: ExerciseSessionUserResult[];
    tags: Tag[];
    contents: Content[];
    taskTemplate: TaskTemplateResource;
    preconditionsSummary: PreconditionsSummary;
    icons?: IconAdapterElement[];
    _links: HypermediaLinks;
    responsible: string;
    patientCanOpenResult?: boolean;

    // Frontend state extension
    state?: string;
    dueDate?: string;
    stateChanges?: TaskStateChange[];
    finished_at: any;
    patientName: string;
    responsibleName: string;
    responsibleFullName: string;
    participants?: any;
    actions: ActionButton[];
    iconColor?: string;
    dashedCardBorder?: boolean;
}

export class TaskStateChange {
    id: number;
    taskEntity?: TaskResource;
    newState: ExerciseSessionState;
    created_at: Date;
    requestingUserObj?: PublicUserInfo;
}

export enum SortTaskPropertyMap {
    TITLE = 'TITLE',
    DESCRIPTION = 'DESCRIPTION',
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT',
    DURATION = 'DURATION',
    START_TIME = 'START_TIME',
    DELAYED_TIME = 'DELAYED_TIME',
    END_TIME = 'END_TIME',
    CONCERNED_USERNAME = 'CONCERNED_USERNAME',
    EXERCISE_SESSION_TYPE = 'EXERCISE_SESSION_TYPE',
    EXERCISE_SESSION_STATE = 'EXERCISE_SESSION_STATE',
    RESPONSIBLE = 'RESPONSIBLE',
    LATEST_STATE_CHANGE = 'LATEST_STATE_CHANGE',
    CALENDAR_EVENT_START_TIME = 'CALENDAR_EVENT_START_TIME',
    EXERCISE_SUB_TYPE = 'EXERCISE_SUB_TYPE',
}

export class AppointmentItem {
    calendarEvent?: ExerciseSessionCalendarEvent;
    appointment?: ExerciseSessionAppointment;
    title: string;
    exerciseType?: ExerciseType;
    id: number;
    exerciseSubType?: ExerciseSubType;
    updated_at?: string;
    exerciseSessionState?: ExerciseSessionState;
    exerciseSessionUserState?: ExerciseSessionUserState;
    presenceType?: PresenceType;
    _links: HypermediaLinks;

    static fromMeetingUserPreviewResource(meetingUserResource: MeetingUserPreviewResource) {
        const resource = new AppointmentItem();
        resource.id = meetingUserResource.id;
        resource.appointment = meetingUserResource.appointment as ExerciseSessionAppointment;
        resource.title = meetingUserResource.meetingTitle;
        resource.exerciseType = ExerciseType.COURSE;
        resource.exerciseSessionState = meetingUserResource.meetingState;
        resource.exerciseSessionUserState = meetingUserResource.state;
        resource.presenceType = meetingUserResource.presenceType;
        resource._links = meetingUserResource._links;

        return resource;
    }
}
